import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { APIGetAssessmentInvitations, APIValidateToken } from "utils/api";

export const AssignmentsContext = createContext({});

const AssignmentsProvider = ({ children }: any): any => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const [user, setUser] = useState(null);
  const [assessments, setAssessments] = useState(null);

  /**
   * if Not logged in redirect to login page
   */
  useEffect(() => {
    if (!isLoggedIn && pathname !== "/login") {
      navigate("/login");
    }
  }, [isLoggedIn, navigate, pathname]);
  /**
   * Gets user info on app start
   */
  const userQuery = useQuery({
    queryKey: ["user_me"],
    queryFn: () => APIValidateToken(),
    enabled: isLoggedIn,
  });
  useEffect(() => {
    if (userQuery.isSuccess) {
      setUser(userQuery.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery.isSuccess]);

  const assessmentInvitationsQuery = useQuery({
    queryKey: ["assessments"],
    queryFn: () => APIGetAssessmentInvitations({user_id: user.id, with_lab: true}),
    enabled: !!user && !!user.id,
  });
  useEffect(() => {
    if (assessmentInvitationsQuery.isSuccess && !assessments) {
      if (assessmentInvitationsQuery.data.data.length > 0) {
        let arr = assessmentInvitationsQuery.data.data.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        setAssessments(arr);
        if (pathname === "/" || pathname === "/login") {
          navigate(`/${arr[0].lab.slug}`);
        }
      } else {
        navigate("/error-404");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentInvitationsQuery.isSuccess]);

  return (
    <AssignmentsContext.Provider value={{ user, isLoggedIn, assessments }}>
      {children}
    </AssignmentsContext.Provider>
  );
};

export const useAssignmentsContext = () => useContext(AssignmentsContext);

export default AssignmentsProvider;
