import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import {
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { authenticate } from "store/slices/auth";
import { APILogout, APIValidateToken, AVATARGetUserAvatarUrl } from "utils/api";

import "./Header.scss";
import { AUTH_STATUS } from "components/Modals/AuthModal";
import { triggerOpenModal } from "store/slices/modals";
import { Avatar } from "@mui/material";
import { removeCognitoTokensFromLocalStorage } from "utils/cognito";
import { initialsFromName } from "utils/utils";
import ProfilePopup from "components/ProfilePopup";
import NotificationsPopup from "components/NotificationsPopup";
import { useTranslation } from "react-i18next";

const Header = () => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.auth.user_me);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // Auth modal
  const handleOpenAuthModal = () =>
    dispatch(triggerOpenModal("modal-auth", AUTH_STATUS.SIGNUP));

  /**
   * Gets user info on app start
   */
  useEffect(() => {
    APIValidateToken()
      .then((res) => res.data)
      .then((user_data) => {
        dispatch(authenticate(true, user_data));
      })
      .catch((error) => {
        console.log("auth error", error);
        dispatch(authenticate(false, null));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  /**
   * Opens auth modal when in Home page, url has #login and not logged in
   */
  useEffect(() => {
    if (pathname === "/" && searchParams.has("login") && !isLoggedIn) {
      handleOpenAuthModal();
    }
    if (pathname === "/" && searchParams.has("login") && isLoggedIn && searchParams.has("forward")) {
      const forward = searchParams.get("forward");
      if (forward.startsWith(process.env.REACT_APP_LANDING_URL)) {
        window.location.href = forward;
      } else {
        navigate("/");
      }
    }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams, isLoggedIn]);

  // const isProfileRoute = pathname.startsWith("/profile");

  /**
   * Profile Popover
   */
  const [anchorElProfilePopup, setAnchorElProfilePopup] = useState(null);

  const handleClickProfilePopup = (event) => {
    setAnchorElProfilePopup(event.currentTarget);
  };

  const handleCloseProfilePopup = () => {
    setAnchorElProfilePopup(null);
  };
  const open = Boolean(anchorElProfilePopup);
  const idProfilePopup = open ? "profile-popup" : undefined;

  /**
   * Notifications Popover
   */
  const [anchorElNotificationsPopup, setAnchorElNotificationsPopup] =
    useState(null);

  // const handleClickNotificationsPopup = (event) => {
  //   setAnchorElNotificationsPopup(event.currentTarget);
  // };

  const handleCloseNotificationsPopup = () => {
    setAnchorElNotificationsPopup(null);
  };

  const openNotificationsPopup = Boolean(anchorElNotificationsPopup);
  const idNotificationsPopup = openNotificationsPopup
    ? "profile-popup"
    : undefined;

  /**
   * Logout
   */
  const [logoutLoading, setLogoutLoading] = useState(false);

  const handleLogout = () => {
    setLogoutLoading(true);
    APILogout()
      .then(() => {
        removeCognitoTokensFromLocalStorage();
        setLogoutLoading(false);
        dispatch(authenticate(false, null));
        handleCloseProfilePopup();
        // Navigate after state is updated
        setTimeout(() => {
          navigate("/?login");
        }, 50);
      })
      .catch((error) => {
        console.log("error", error);
        setLogoutLoading(false);
      });
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <div className="navbar-placeholder"></div>
      <section className={`navbar ${isMobileMenuOpen ? "mobile-open" : ""}`}>
        <div className="hamburger" onClick={toggleMobileMenu}>
          <div className={`bar ${isMobileMenuOpen ? "mobile-open" : ""}`}></div>
        </div>
        <div className="logo">
          <Link href={`${process.env.REACT_APP_LANDING_URL}/home`}>
            <img src={require("assets/logo.svg").default} alt="logo" />
          </Link>
        </div>
        {/* {isLoggedIn && isProfileRoute ? (
          <div className="search">
            <Input
              id="outlined-basic"
              placeholder={t("search-labs-event-progress")}
              className="input"
              aria-label="search"
              type="search"
              disableUnderline
              endAdornment={
                <InputAdornment position="end">
                  <img
                    src={require("assets/search_icon.svg").default}
                    alt="search"
                    className="search_icon"
                  />
                </InputAdornment>
              }
            />
          </div>
        ) : (
          <></>
        )} */}
        <div className={`menus ${isMobileMenuOpen ? "mobile-open" : ""}`}>
          <div className="navigation">
            <NavLink to={`/`} onClick={() => setMobileMenuOpen(false)}>
              {t("home")}
            </NavLink>
            <NavLink to={"/labs"} onClick={() => setMobileMenuOpen(false)}>
              {t("labs")}
            </NavLink>
            <NavLink to={`${process.env.REACT_APP_LANDING_URL}/community`} onClick={() => setMobileMenuOpen(false)}>
              {t("community")}
            </NavLink>
            <Link
              onClick={(e) => {
                e.preventDefault();
                let w = window as any;
                if (w.HubSpotConversations && w.HubSpotConversations.widget) {
                  (window as any).HubSpotConversations.widget.open();
                }
              }}
            >
              {t("contact")}{" "}
            </Link>
          </div>
        </div>
        <div className="actions">
          {isLoggedIn && user ? (
            <div className="toolbar">
              {/* <Link onClick={handleClickNotificationsPopup}>
                <img
                  src={require("assets/notifications.svg").default}
                  alt="Notifications"
                  className="bell_icon"
                />
              </Link> */}
              <NotificationsPopup
                id={idNotificationsPopup}
                open={openNotificationsPopup}
                anchorEl={anchorElNotificationsPopup}
                handleClose={handleCloseNotificationsPopup}
              />
              <Link
                onClick={handleClickProfilePopup}
                component="button"
                underline="none"
                className="profile_icon"
              >
                <Avatar
                  className="!bg-[#d9d9d9] !font-poppins !text-[12px] font-semibold !text-black"
                  src={AVATARGetUserAvatarUrl(user)}
                  alt="profile"
                  sx={{
                    border: "2px solid rgba(64, 126, 201, 1)",
                  }}
                >
                  {initialsFromName(user.firstname + " " + user.lastname)}
                </Avatar>
              </Link>
              <ProfilePopup
                id={idProfilePopup}
                open={open}
                anchorEl={anchorElProfilePopup}
                handleClose={handleCloseProfilePopup}
                handleLogout={handleLogout}
                logoutLoading={logoutLoading}
                user={user}
              />
            </div>
          ) : (
            <Link onClick={handleOpenAuthModal} className="login">
              {t("sign-up-0")}{" "}
            </Link>
          )}
        </div>
      </section>
    </>
  );
};

export default Header;
