import "./_index.scss";
import { Fade, Link, Switch } from "@mui/material";
import { useState } from "react";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import { useTranslation } from "react-i18next";

const CustomizeCookieView = (props): JSX.Element => {
  const { handleClose, setCookiePreferences, cookiePreferences } = props;
  const [showMoreDetails, setShowMoreDetails] = useState(false);
  const { t } = useTranslation();

  const handleToggle = (key: string) => {
    setCookiePreferences((prev) => {
      return { ...prev, [key]: !prev[key] };
    });
  };

  return (
    <div className="flex flex-col content-center items-center overflow-y-auto text-center">
      <div className="w-[75px] self-center">
        <img src={require("assets/cookie-banner.svg").default} alt="cookie" />
      </div>
      <div className="flex flex-col content-center gap-[14px] pb-[50px] pt-[16px]">
        <h5 className="custom-text-volkhov-700-24px text-[#111827]">
          {t("customise-your-preferences")}{" "}
        </h5>
        <div className="cookie-customization-toggles flex w-full max-w-[370px] flex-col">
          <div className="flex flex-col justify-center text-left">
            <div className="flex w-full flex-row content-between items-center justify-between">
              <h3 className="custom-text-poppins-600-18px-1_5 text-[#141414]">
                {t("necessary")}{" "}
              </h3>
              <Switch checked={true} />
            </div>
            {showMoreDetails && (
              <p className="custom-text-poppins-400-16px-1_87 max-w-[370px] text-[#6B7280]">
                {t(
                  "necessary-cookies-help-make-a-website-usable-by-enabing-basic-functions-like-page-navigation-and-access-to-secure-of-the-website-the-website-cannot-function-properly-without-these-cookies",
                )}{" "}
              </p>
            )}
          </div>

          <div className="flex flex-col justify-center text-left">
            <div className="flex w-full flex-row content-between items-center justify-between">
              <h3 className="custom-text-poppins-600-18px-1_5 text-[#141414]">
                {t("analytics-and-performance")}{" "}
              </h3>
              <Switch
                checked={cookiePreferences.analytics}
                onChange={() => {
                  handleToggle("analytics");
                }}
              />
            </div>
            {showMoreDetails && (
              <p className="custom-text-poppins-400-16px-1_87 max-w-[370px] text-[#6B7280]">
                {t(
                  "analytics-and-performance-cookies-help-website-owners-to-understand-how-visitors-interact-with-websitesby-collecting-and-reporting-information-anonymously",
                )}{" "}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="w-100 flex h-[50px] w-[70%] flex-col content-center gap-[5px] md:h-auto md:w-[100%]">
        <div className="flex flex-row content-center gap-[10px]">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleClose(cookiePreferences.analytics);
            }}
            className="custom-text-poppins-600-16px mx-auto min-h-[54px] w-full max-w-[211px] items-center rounded-[20px] bg-blue px-[16px] py-[6px] text-white transition-colors duration-300 ease-in-out hover:bg-blue-dark"
          >
            {t("save-preferences")}{" "}
          </button>
        </div>
        {}
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowMoreDetails(!showMoreDetails);
          }}
          className="custom-text-poppins-400-18px mx-auto w-fit cursor-pointer rounded-[8px] object-none px-[20px] py-[10px] !text-[#6B7280] underline"
        >
          {showMoreDetails ? t("hide-details") : t("more-details")}
        </button>
      </div>
    </div>
  );
};

const AcceptOrDeclineCookieView = (props: {
  handleClose;
  setShowCustomizationView;
}): JSX.Element => {
  const { handleClose, setShowCustomizationView } = props;
  const { t } = useTranslation();

  return (
    <div className="flex flex-col content-center items-center text-center">
      <div className="w-[75px] self-center">
        <img src={require("assets/cookie-banner.svg").default} alt="cookie" />
      </div>
      <div className="flex flex-col content-center gap-[14px] pb-[50px] pt-[16px]">
        <h5 className="custom-text-volkhov-700-24px text-[#111827]">
          {t("have-a-cookie")}{" "}
        </h5>
        <p className="custom-text-poppins-300-18px text-[#6B7280]">
          {t(
            "our-website-use-cookies-by-continuing-we-assume-your-permission-to-deploy-cookies-as-detailed-in-our",
          )}
          r{" "}
          <Link href={`${process.env.REACT_APP_LANDING_URL}/privacy`}>
            {t("privacy-policy")}{" "}
          </Link>
          .
        </p>
      </div>
      <div className="w-100 flex h-[50px] w-[70%] flex-col content-center gap-[5px] md:h-auto md:w-[100%]">
        <div className="flex flex-row content-center gap-[10px]">
          <button
            onClick={(e) => {
              e.preventDefault();
              handleClose(false);
            }}
            className="color-[#9CA3AF] custom-text-poppins-400-16px w-full rounded-[20px] border-[1px] border-[#9CA3AF] bg-transparent px-[5px] shadow-[0px_3px_12px_0px_rgba(76,76,80,0.11)]"
          >
            {t("decline-all-cookies")}{" "}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            className="custom-text-poppins-600-16px mx-auto min-h-[54px] w-full max-w-[388px] items-center rounded-[20px] bg-blue px-[16px] py-[6px] text-white transition-colors duration-300 ease-in-out hover:bg-blue-dark"
          >
            {t("accept-all-cookies")}{" "}
          </button>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowCustomizationView(true);
          }}
          className="custom-text-poppins-400-18px mx-auto w-fit cursor-pointer rounded-[8px] object-none px-[20px] py-[10px] !text-[#6B7280] underline"
        >
          {t("customize-cookie")}{" "}
        </button>
      </div>
    </div>
  );
};

const CookiePolicyModal = ({ id, open, handleClose }) => {
  const [showCustomizationView, setShowCustomizationView] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: true,
  });
  const { t } = useTranslation();

  const acceptCookieAndCloseModal = (analytics = true) => {
    enforceCookiePreferences(analytics);

    localStorage.setItem(
      process.env.REACT_APP_COOKIE_BANNER_LOCALSTORAGE_KEY,
      "true",
    );
    handleClose();
  };

  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={true} in={open}>
        <div
          role="dialog"
          aria-modal="true"
          aria-label={t("cookie-banner")}
          className="cookie-banner fixed bottom-[10px] left-[10px] right-[10px] z-[9999] m-0 max-h-[calc(100vh-80px)] max-w-[calc(100%-20px)] overflow-y-auto rounded-[20px] bg-white px-[23px] py-[20px] shadow-[0px_14px_42px_0px_rgba(8,15,52,0.06),0px_7px_29px_0px_rgba(100,100,111,0.20)] md:bottom-[50px] md:left-[20px] md:right-auto md:max-w-[500px] md:px-[42px] md:py-[24px]"
          tabIndex={-1}
        >
          {showCustomizationView ? (
            <div>
              <CustomizeCookieView
                cookiePreferences={cookiePreferences}
                setCookiePreferences={setCookiePreferences}
                handleClose={acceptCookieAndCloseModal}
              />
            </div>
          ) : (
            <div>
              <AcceptOrDeclineCookieView
                handleClose={acceptCookieAndCloseModal}
                setShowCustomizationView={setShowCustomizationView}
              />
            </div>
          )}
        </div>
      </Fade>
    </TrapFocus>
  );
};

/**
 * @param enforceAllToTrue - If true, all cookies will be set to true. Used to enforce cookie preferences to false if user has not chosen his preferences.
 */
export const initCookiePreferences = (enforceDisableConsent = false) => {
  if (enforceDisableConsent) {
    enforceCookiePreferences(false);
    return;
  }

  const cookiePreferences = localStorage.getItem("cookiePreferences");
  if (cookiePreferences) {
    let parsedCookiePreferences = JSON.parse(cookiePreferences);
    enforceCookiePreferences(parsedCookiePreferences.analytics);
  } else {
    enforceCookiePreferences(false);
  }
};

const enforceCookiePreferences = (analytics) => {
  if (analytics) {
    (window as any).clarity("consent");
  } else {
    (window as any).clarity("consent", false);
  }
  localStorage.setItem(
    "cookiePreferences",
    JSON.stringify({ necessary: true, analytics: analytics }),
  );
};

export default CookiePolicyModal;
