import { InfoOutlined } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { createContext, useContext, useState } from "react";

const DebugContext = createContext({
  debugMode: false,
  toggleDebugMode: () => {},
});

let DebugProvider;
let useDebugMode;

if (process.env.REACT_APP_BUILD_ENV === "true") {
  DebugProvider = ({ children }) => {
    const [debugMode, setDebugMode] = useState(
      process.env.REACT_APP_BUILD_ENV === "true",
    );

    const toggleDebugMode = () => {
      setDebugMode((prevMode) => !prevMode);
    };
    /**
     * Debug details popover
     */
    const [anchorPopover, setAnchorPopover] = useState(null);

    const handleOpenPopover = (event) => {
      setAnchorPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorPopover(null);
    };
    const open = Boolean(anchorPopover);
    const id = open ? "details-popover" : undefined;

    return (
      <DebugContext.Provider value={{ debugMode, toggleDebugMode }}>
        <div className="fixed bottom-[50px] left-[38px] z-[9999] flex flex-row gap-1">
          <button
            onClick={toggleDebugMode}
            className="rounded-full bg-blue px-4 py-2 text-white shadow-lg transition duration-150 ease-in-out hover:bg-blue-dark"
          >
            Debug - ({debugMode ? "ON" : "OFF"})
          </button>
          {debugMode && (
            <div className="content-center">
              <button
                aria-describedby={id}
                onClick={handleOpenPopover}
                className="rounded-full bg-blue p-1 text-white shadow-lg transition duration-150 ease-in-out hover:bg-blue-dark"
              >
                <InfoOutlined />
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    boxShadow:
                      "0px 7px 29px 0px rgba(100, 100, 111, 0.20)!important",
                  },
                }}
              ></Popover>
            </div>
          )}
        </div>
        {children}
      </DebugContext.Provider>
    );
  };

  useDebugMode = () => useContext(DebugContext);
} else {
  // Placeholder components for production
  DebugProvider = ({ children }) => <>{children}</>;
  useDebugMode = () => ({ debugMode: false, toggleDebugMode: () => {} });
}

export { DebugProvider, useDebugMode };
