import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const TOSCheckbox = (props) => {
  const { formik } = props;
  const tos_ref = useRef(null);
  const submitting = formik?.isSubmitting;
  const { t } = useTranslation();
  
  useEffect(() => {
    /**
     * Scroll to TOS checkbox when it's the only one that's invalid
     */
    if (
      formik?.isSubmitting &&
      Object.keys(formik.errors).length === 1 &&
      formik.errors.tos
    ) {
      tos_ref.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting]);

  return (
    <>
      <div className="auth-form-terms-and-conditions">
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                inputRef={tos_ref}
                checked={formik.values.tos}
                onChange={(e) => {
                  formik.setFieldValue("tos", e.target.checked);
                }}
              />
            }
            label={t('by-signing-up-i-agree-that-i-have-read-and-accepted-the-terms-of-use-and-privacy-policy')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.tos && formik.errors.tos && (
            <FormHelperText>
              {formik.touched.tos ? formik.errors.tos : " "}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </>
  );
};

export default TOSCheckbox;
