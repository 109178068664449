import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  labEvents: [
    {
      type: "PROVISIONING",
      date: new Date().toString(),
      data: {
        feedback: "Lab has been created successfully.",
        status: "success",
      },
    },
  ],
};

// Slice

export const labEventsSlice = createSlice({
  name: "labEvents",
  initialState,
  reducers: {
    addLabEvent: (state, action) => {
      const newLabEvents = Array.isArray(action.payload) ? action.payload : [action.payload];
      return {
        ...state,
        labEvents: [...state.labEvents, ...newLabEvents],
      };
    },
  },
});

export default labEventsSlice.reducer;

// Actions

export const { addLabEvent } = labEventsSlice.actions;

export const updateLabEvents = (event) => (dispatch) => {
  try {
    dispatch(addLabEvent(event));
  } catch (error) {
    return console.error(error.message);
  }
};
