export enum FeatureFlags {
  Pricing = "pricing",
  ServiceAccess = "service_access",
}

const flags = {
  [FeatureFlags.Pricing]: process.env.REACT_APP_FEATURE_FLAG_PRICING === "true",
  [FeatureFlags.ServiceAccess]: process.env.REACT_APP_FEATURE_FLAG_SERVICE_ACCESS === "true",
};

export const useFeatureFlag = (flag: FeatureFlags): boolean => {
  return flags[flag];
};
