import { createSlice } from "@reduxjs/toolkit";
import { refreshChatWidget, refreshVisitorChatWidget } from "utils/utils";

const initialState = {
  isLoggedIn: false,
  user_me: null
};

// Slice

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateSuccess: (state, action) => {
      return {
        ...state,
        user_me: action.payload.user_me,
        isLoggedIn: action.payload.isLoggedIn,
      };
    },
    updateUserMeSuccess: (state, action) => {
      return {
        ...state,
        user_me: action.payload.user_me,
      };
    },
  },
});

export default authSlice.reducer;

// Actions

export const { authenticateSuccess, updateUserMeSuccess } = authSlice.actions;

export const authenticate = (isLoggedIn, user_me) => (dispatch) => {
  try {
    dispatch(authenticateSuccess({isLoggedIn, user_me}));

    // if user is logged in, refresh hubspot widget
    if (isLoggedIn) {
      refreshChatWidget(user_me._sensitive?.email, user_me._hs_token);
    } else {
      refreshVisitorChatWidget();
    }

  } catch (error) {
    return console.error(error.message);
  }
};

export const updateUserMe = (user_me) => (dispatch) => {
  try {
    dispatch(updateUserMeSuccess({user_me}));
  } catch (error) {
    return console.error(error.message);
  }
};
