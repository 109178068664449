import { configureStore } from '@reduxjs/toolkit'
import auth from './slices/auth';
import embedded_community from './slices/embedded_community';
import lab_events from './slices/lab_events';
import modals from './slices/modals';
import notifications from './slices/notifications';

export const store = configureStore({
  reducer: {
    auth,
    embedded_community,
    lab_events,
    modals,
    notifications
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

