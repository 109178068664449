import "styles/global.scss";
import "../../i18n";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "./MainLayout.scss";
import { Outlet } from "react-router-dom";
import GoToTopButton from "components/GoToTopButton";

const MainLayout = (props: {children?}) => {
  return (
    <div className="main-layout">
      <GoToTopButton />
      <div className="container">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
