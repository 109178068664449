import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Centrifuge } from "centrifuge";
import { APIGetNotificationsToken } from "utils/api";
import { pushNotification } from "store/slices/notifications";
import { useDebugMode } from "components/DebugContext/DebugContext";

const NotificationsProvider = () => {
  const user = useAppSelector((state) => state.auth.user_me);
  const dispatch = useAppDispatch();
  const { debugMode } = useDebugMode();

  const query = useQuery({
    queryKey: ["subscription-plans", user],
    queryFn: APIGetNotificationsToken,
    enabled: !!user,
  });

  useEffect(() => {
    if (!user || !user.id || !query.isSuccess) {
      return;
    }

    let centrifuge: Centrifuge | null = null;

    const init = async () => {
      try {
        centrifuge = new Centrifuge(
          `${process.env.REACT_APP_NOTIFICATIONS_URL}/connection/websocket`,
          {
            token: query.data.data.token,
          }
        );
        const sub = centrifuge.newSubscription(`notifications#${user.id}`);
        sub.on("publication", function (ctx) {
          dispatch(pushNotification(ctx.data));
        });

        sub.subscribe();
        centrifuge.connect();
        
        if (debugMode) {
          centrifuge.on("connected", function (ctx) {
            console.log("Centrifuge connected.");
          });
          centrifuge.on("disconnected", function (ctx) {
            console.log("Centrifuge disconnected.");
          });
        }
      } catch (error) {
        if (debugMode) console.error("Error initializing Centrifuge:", error);
      }
    };

    init();

    return () => {
      if (centrifuge) {
        centrifuge.disconnect();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, query]);

  return <></>;
};

export default NotificationsProvider;
