import { Link, Popover } from "@mui/material";
import LoadingImage from "components/LoadingImage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProfilePopup = (props) => {
  const navigate = useNavigate();
  const { id, open, anchorEl, handleClose, handleLogout, logoutLoading } =
    props;
  const { t } = useTranslation();

  if (!open) {
    return null;
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            p: "21px 18px",
            borderRadius: "20px",
            boxShadow: "0px 7px 29px rgba(100, 100, 111, 0.2)",
            maxWidth: "226px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          },
        },
      }}
    >
      <Link
        className="popover-menu-item !mx-[8px] flex max-w-full cursor-pointer flex-row flex-nowrap items-center gap-[8px] !no-underline"
        onClick={() => {
          handleClose();
          navigate("/profile/personal-details");
        }}
      >
        <img
          src={require("assets/user.svg").default}
          alt="rating"
          className="h-[16px] w-[16px]"
        />
        <span className="text-black">{t("profile-0")}</span>
      </Link>
      <Link
        className="popover-menu-item !mx-[8px] flex max-w-full cursor-pointer flex-row flex-nowrap items-center gap-[8px] !no-underline"
        onClick={() => {
          handleClose();
          navigate("/profile/favorite-labs");
        }}
      >
        <img
          src={require("assets/heart_icon.svg").default}
          alt="rating"
          className="h-[16px] w-[16px]"
        />
        <span className="text-black">{t("favorite")}</span>
      </Link>
      <Link
        className="popover-menu-item !mx-[8px] flex max-w-full cursor-pointer flex-row flex-nowrap items-center gap-[8px] !no-underline"
        onClick={(e) => {
          e.preventDefault();
          let w = window as any;
          if (w.HubSpotConversations && w.HubSpotConversations.widget) {
            handleClose();
            (window as any).HubSpotConversations.widget.open();
          }
        }}
      >
        <img
          src={require("assets/help_icon.svg").default}
          alt="rating"
          className="h-[16px] w-[16px]"
        />
        <span className="text-black">{t("help")}</span>
      </Link>
      <hr className="h-[1px] border-black bg-black pr-[3px] opacity-30" />
      <Link
        className="popover-menu-item !mx-[8px] flex max-w-full cursor-pointer flex-row flex-nowrap items-center gap-[8px] !text-red !no-underline"
        onClick={handleLogout}
      >
        {logoutLoading ? (
          <LoadingImage className="!h-[20px]" />
        ) : (
          <>
            <img
              src={require("assets/log-out-red.svg").default}
              alt="rating"
              className="h-[16px] w-[16px] text-red"
            />
            <span className="text-red">{t("logout")}</span>
          </>
        )}
      </Link>
    </Popover>
  );
};

export default ProfilePopup;
