import "./_index.scss";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import LoadingImage from "components/LoadingImage";
import { useTranslation } from "react-i18next";
import config from "utils/config";

const VerifyForm = (props) => {
  const { formik, submitError, setSubmitError } = props;
  const { t } = useTranslation();
  const CONTENT = [
    {
      icon: require("assets/monetization_on_black_24dp.svg").default,
      title: t("absolutely-free"),
      description: t("no-hidden-charges-no-credit-card-required"),
    },
    {
      icon: require("assets/dynamic_form_black_24dp.svg").default,
      title: t("fast-and-easy"),
      description: t("sign-up-and-get-access-instantly"),
    },
    {
      icon: require("assets/ic_assignment_ind.svg").default,
      title: t("your-own-data"),
      description: t("set-your-prefrences-and-enjoy-your-free-trial"),
    },
    {
      icon: require("assets/ic_stars.svg").default,
      title: t("unlimited-features"),
      description: t("get-access-to-all-features"),
    },
  ];

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/login_illustration.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.signup_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">
          {t("get-your-free-n3-month-trial-now")}{" "}
        </div>
        <p className="auth-header-subtitle">
          {t(
            "and-start-improving-your-skills-faster-with-simplified-nand-straight-to-the-point-labs-created-for-you-0",
          )}{" "}
        </p>
        <div className="auth-header-content">
          {CONTENT.map(
            (
              item,
              index, // Adjust if VERIFY has different content
            ) => (
              <div key={index} className="auth-header-content-details">
                <img
                  src={item.icon}
                  alt="icon"
                  className="auth-header-content-details-icon"
                />
                <div className="auth-header-content-details-container">
                  <div className="auth-header-content-details-container-title">
                    {item.title}
                  </div>
                  <div className="auth-header-content-details-container-description">
                    {item.description}
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form"
        >
          <div className="auth-form-verify">
            <Grid container>
              <Grid item xs={12}>
                <p className="auth-form-verify-title">
                  {t("please-verify-you-account")}{" "}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="auth-form-verify-subtitle">
                  {t(
                    "enter-the-digit-code-we-sent-to-your-email-naddress-to-verify-your-new-account",
                  )}{" "}
                </p>
              </Grid>
              <Grid item xs={12} style={{ margin: "24px 0" }}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label={t("verification-code")}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {formik.isSubmitting ? (
                  <LoadingImage></LoadingImage>
                ) : (
                  <FormControl fullWidth>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmitError("");
                        formik.handleSubmit();
                      }}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      className="auth-form-confirm-btn hover:!bg-blue-dark"
                      variant="contained"
                    >
                      {t("verify-and-confirm")}
                    </Button>
                    <FormHelperText>{submitError}</FormHelperText>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyForm;
