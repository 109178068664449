import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
};

// Slice

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action) {
      console.log("Adding notification", action.payload);
      state.notifications.push(action.payload);
    },
    clearNotifications(state) {
      state.notifications = [];
    },
  },
});

export default notificationsSlice.reducer;

// Actions

export const { addNotification, clearNotifications } = notificationsSlice.actions;

export const pushNotification = (notif) => (dispatch) => {
  try {
    dispatch(addNotification(notif));
  } catch (error) {
    return console.error(error.message);
  }
};
