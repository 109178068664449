import "./_index.scss";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import LoadingImage from "components/LoadingImage";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import config from "utils/config";

const OnBoardingForm = (props) => {
  const {
    formik,
    submitError,
    successMessage,
    currentOnboarding,
    setCurrentOnboarding,
    hasVerifyNameOnboarding,
    setHasVerifyNameOnboarding,
    verifyUserInfoFormik,
  } = props;
  const [isDone, setIsDone] = useState(false);
  const user = useAppSelector((state) => state.auth.user_me);
  const { t } = useTranslation();

  const [data, setData] = useState([
    // {
    //   id: "onboarding-1",
    //   title: "One last quick profile setup & you are ready to GO🚀",
    //   questions: [
    //     {
    //       answers_type: "select",
    //       id: "onbaroding-question-1",
    //       proposed_answers: ["Student", "Engineer", "Professional", "CEO"],
    //       title: "What is your current status ?",
    //       required: true,
    //     },
    //     {
    //       answers_type: "multiple",
    //       id: "onbaroding-question-2",
    //       proposed_answers: [
    //         "Devops",
    //         "Devops",
    //         "Cloud",
    //         "Cloud",
    //         "Sysadmin",
    //         "Sysadmin",
    //         "Security",
    //         "Security",
    //         "Networking",
    //         "Networking",
    //       ],
    //       title: "What is your professional focus ?",
    //       required: true,
    //     },
    //   ],
    // },
    // {
    //   id: "onboarding-2",
    //   title: "One last quick profile setup & you are ready to GO🚀",
    //   questions: [
    //     {
    //       answers_type: "text",
    //       id: "onbaroding-question-3",
    //       title: "Custom message",
    //       required: "true",
    //     },
    //   ],
    // },
  ]);
  useEffect(() => {
    if (user) {
      setData(user._feedback_required);
      if (user._needconfirmation && !hasVerifyNameOnboarding) {
        setHasVerifyNameOnboarding(true);
        verifyUserInfoFormik.setValues({
          user: {
            email: user._sensitive.email,
            ...user
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (data && data.length > 0) {
      setCurrentOnboarding(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data && data.length > 0) {
      if (currentOnboarding < data.length) {
        const onboarding = data[currentOnboarding];
        formik.setValues({});
        onboarding.questions.forEach((item) => {
          if (item.answers_type === "select") {
            formik.setFieldValue(item.id, "");
          } else if (item.answers_type === "multiple") {
            formik.setFieldValue(item.id, []);
          } else {
            formik.setFieldValue(item.id, "");
          }
        });
      } else {
        setIsDone(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOnboarding, data]);

  if (isDone && hasVerifyNameOnboarding) {
    return (
      <>
        <div className="auth-header">
          <img
            src={require("assets/onboarding-illust.svg").default}
            alt="logo"
            className="auth-header-illustration"
            style={{
              height: config.auth_popup.onboarding.left_side.illustrationHeight,
            }}
          />
          <div className="auth-header-title !mr-0">
            {t("lets-customize-your-nlearning-journey")}
          </div>
        </div>
        <div className="auth-form-container">
          <div
            style={{
              height: "fit-content",
              justifyContent: "center",
              display: "flex",
            }}
            className="auth-form !max-w-[502px]"
          >
            <div className="my-0">
              <Grid container>
                <Grid container rowGap={"60px"}>
                  <Grid item xs={12}>
                    <p className="custom-text-poppins-500-18px !w-[502px] text-[#202020]">
                      {t('one-last-quick-profile-setup-and-you-are-ready-to-go')} </p>
                  </Grid>
                  <Grid
                    container
                    direction={"column"}
                    rowGap={"27px"}
                    xs={12}
                    className="!max-w-[468px]"
                  >
                    <div>
                      <InputLabel className="!custom-text-poppins-500-16px mb-[13px] !text-black">
                        {t('first-name')} <span className="text-red">*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={"user.firstname"}
                        name={"user.firstname"}
                        placeholder={t('ben')}
                        InputProps={{ sx: { borderRadius: "8px" } }}
                        value={verifyUserInfoFormik.values.user.firstname}
                        onChange={verifyUserInfoFormik.handleChange}
                        onBlur={verifyUserInfoFormik.handleBlur}
                        error={
                          verifyUserInfoFormik.values.user?.firstname &&
                          Boolean(verifyUserInfoFormik.errors.user?.firstname)
                        }
                        helperText={
                          verifyUserInfoFormik.touched.user?.firstname &&
                          verifyUserInfoFormik.errors.user?.firstname
                        }
                      />
                    </div>
                    <div>
                      <InputLabel className="!custom-text-poppins-500-16px mb-[13px] !text-black">
                        {t('last-name')} <span className="text-red">*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={"user.lastname"}
                        name={"user.lastname"}
                        placeholder={t('leo')}
                        InputProps={{ sx: { borderRadius: "8px" } }}
                        value={verifyUserInfoFormik.values.user.lastname}
                        onChange={verifyUserInfoFormik.handleChange}
                        onBlur={verifyUserInfoFormik.handleBlur}
                        error={
                          verifyUserInfoFormik.values.user?.lastname &&
                          Boolean(verifyUserInfoFormik.errors.user?.lastname)
                        }
                        helperText={
                          verifyUserInfoFormik.touched.user?.lastname &&
                          verifyUserInfoFormik.errors.user?.lastname
                        }
                      />
                    </div>
                    <div>
                      <InputLabel className="!custom-text-poppins-500-16px mb-[13px] !text-black">
                        {t('country')} <span className="text-red">*</span>
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={"user.country"}
                        name={"user.country"}
                        select
                        label={t("select-country")}
                        placeholder="France"
                        InputProps={{ sx: { borderRadius: "8px" } }}
                        SelectProps={{
                          MenuProps: {
                            sx: {
                              "& .MuiPaper-root": {
                                borderRadius: "8px",
                                boxShadow:
                                  "0px 7px 29px 0px rgba(100, 100, 111, 0.20)!important",
                              },
                            },
                          },
                        }}
                        value={verifyUserInfoFormik.values.user.country}
                        onChange={verifyUserInfoFormik.handleChange}
                        onBlur={verifyUserInfoFormik.handleBlur}
                        error={
                          verifyUserInfoFormik.values.user?.country &&
                          Boolean(verifyUserInfoFormik.errors.user?.country)
                        }
                        helperText={
                          verifyUserInfoFormik.touched.user?.country &&
                          verifyUserInfoFormik.errors.user?.country
                        }
                      >
                        {config.countries.map((country, index) => {
                          return (
                            <MenuItem
                              className="!custom-text-poppins-400-12px !text-black"
                              key={index}
                              value={country.toLowerCase()}
                            >
                              {country}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    className="!max-w-[468px]"
                  >
                    {verifyUserInfoFormik.isSubmitting ? (
                      <LoadingImage></LoadingImage>
                    ) : (
                      <FormControl fullWidth className="!max-w-[293px]">
                        <Button
                          onClick={verifyUserInfoFormik.handleSubmit}
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          className="auth-form-confirm-btn mx-auto hover:!bg-blue-dark"
                          variant="contained"
                        >
                          {t("validate")} 🚀
                        </Button>
                        <FormHelperText>{submitError}</FormHelperText>
                        <FormHelperText style={{ color: "#10B981" }}>
                          {successMessage}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!data || (data && data.length === 0) || isDone) {
    return (
      <div className="auth-header col-span-full !flex !max-w-full">
        <img
          src={require("assets/onboarding-illust.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.onboarding.left_side.illustrationHeight,
          }}
        />
        <div className="auth-header-title !mr-0">{t("welcome-aboard")}</div>
        <FormControl fullWidth>
          <button
            onClick={(e) => {
              e.preventDefault();
              props.handleClose();
            }}
            className="custom-text-poppins-600-14px z-10 mx-auto h-[60px] w-[170px] rounded-[22px] bg-blue text-white shadow-[0px_5px_16.6px_0px_rgba(64,126,201,0.30)] transition-colors duration-300 ease-in-out hover:bg-blue-dark"
          >
            {t("start-your-journey")}
          </button>
        </FormControl>
      </div>
    );
  }

  if (!data || (data && data.length === 0)) {
    return (
      <div className="auth-header col-span-full !max-w-full">
        <img
          src={require("assets/onboarding-illust.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.onboarding.left_side.illustrationHeight,
          }}
        />
        <div className="auth-header-title !mr-0">
          {t("no-onboarding-data-found")}
        </div>
      </div>
    );
  }
  if (data && data.length > 0 && currentOnboarding < data.length) {
    const onboarding = data[currentOnboarding];
    return (
      <>
        <div className="auth-header">
          <img
            src={require("assets/onboarding-illust.svg").default}
            alt="logo"
            className="auth-header-illustration"
            style={{
              height: config.auth_popup.onboarding.left_side.illustrationHeight,
            }}
          />
          <div className="auth-header-title !mr-0">
            {t("lets-customize-your-nlearning-journey")}{" "}
          </div>
        </div>
        <div className="auth-form-container">
          <div
            style={{
              height: "fit-content",
              justifyContent: "center",
              display: "flex",
            }}
            className="auth-form !max-w-[502px]"
          >
            <div className="my-0">
              <Grid container>
                <Grid container rowGap={"60px"}>
                  <Grid item xs={12}>
                    <p className="custom-text-poppins-500-18px !w-[502px] text-[#202020]">
                      {onboarding.title}
                    </p>
                  </Grid>
                  {onboarding.questions.map((item, index) => {
                    return (
                      <>
                        {item.answers_type === "select" ? (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            className="!max-w-[468px]"
                          >
                            <InputLabel className="!custom-text-poppins-600-18px mb-[20px] !text-[#407EC9]">
                              {item.title}
                              {item.required === true ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                            </InputLabel>
                            <TextField
                              fullWidth
                              id={item.id}
                              name={item.id}
                              select
                              label={t("select")}
                              InputProps={{ sx: { borderRadius: "8px" } }}
                              SelectProps={{
                                MenuProps: {
                                  sx: {
                                    "& .MuiPaper-root": {
                                      borderRadius: "8px",
                                      boxShadow:
                                        "0px 7px 29px 0px rgba(100, 100, 111, 0.20)!important",
                                    },
                                  },
                                },
                              }}
                              value={
                                formik.values[item.id]
                                  ? formik.values[item.id]
                                  : ""
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched[item.id] &&
                                Boolean(formik.errors[item.id])
                              }
                              helperText={
                                formik.touched[item.id] &&
                                formik.errors[item.id]
                              }
                            >
                              {item.proposed_answers.map((answer, index) => {
                                return (
                                  <MenuItem
                                    className="!custom-text-poppins-400-12px !text-black"
                                    key={index}
                                    value={answer}
                                  >
                                    {answer}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          </Grid>
                        ) : item.answers_type === "multiple" ? (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            className="!max-w-[468px]"
                          >
                            <InputLabel className="!custom-text-poppins-600-18px mb-[20px] !text-[#407EC9]">
                              {item.title}
                              {item.required === true ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                            </InputLabel>
                            <Grid container rowGap={"13px"}>
                              {item.proposed_answers.map((answer, index) => {
                                return (
                                  <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    width={"150px"}
                                    key={index}
                                  >
                                    <FormControl
                                      fullWidth
                                      component="fieldset"
                                      error={
                                        formik.touched[item.id] &&
                                        Boolean(formik.errors[item.id])
                                      }
                                    >
                                      <FormControlLabel
                                        disableTypography={true}
                                        className="!custom-text-poppins-400-12px !text-black"
                                        control={
                                          <Checkbox
                                            icon={
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                              >
                                                <rect
                                                  x="0.5"
                                                  y="0.5"
                                                  width="19"
                                                  height="19"
                                                  rx="3.5"
                                                  stroke="#D1D1D6"
                                                />
                                              </svg>
                                            }
                                            className="!p-0 !pr-[8px]"
                                            value={answer}
                                            checked={
                                              formik.values[item.id]
                                                ? formik.values[
                                                    item.id
                                                  ].includes(answer)
                                                : false
                                            }
                                            onChange={formik.handleChange}
                                            name={item.id}
                                          />
                                        }
                                        label={answer}
                                      />
                                    </FormControl>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            className="!max-w-[468px]"
                          >
                            <InputLabel className="!custom-text-poppins-600-18px mb-[20px] !text-[#407EC9]">
                              {item.title}
                              {item.required === true ? (
                                <span className="text-red">*</span>
                              ) : (
                                ""
                              )}
                            </InputLabel>
                            <TextField
                              fullWidth
                              id={item.id}
                              name={item.id}
                              label={t("type-your-answer-here")}
                              InputProps={{ sx: { borderRadius: "8px" } }}
                              value={formik.values[item.id] ?? ""}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched[item.id] &&
                                Boolean(formik.errors[item.id])
                              }
                              helperText={
                                formik.touched[item.id] &&
                                formik.errors[item.id]
                              }
                            />
                          </Grid>
                        )}
                      </>
                    );
                  })}
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                    className="!max-w-[468px]"
                  >
                    {formik.isSubmitting ? (
                      <LoadingImage></LoadingImage>
                    ) : (
                      <FormControl fullWidth className="!max-w-[293px]">
                        <Button
                          onClick={formik.handleSubmit}
                          disableElevation
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          className="auth-form-confirm-btn mx-auto hover:!bg-blue-dark"
                          variant="contained"
                        >
                          {t("validate")} 🚀
                        </Button>
                        <FormHelperText>{submitError}</FormHelperText>
                        <FormHelperText style={{ color: "#10B981" }}>
                          {successMessage}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default OnBoardingForm;
