const PoweredByScalyz = () => {
  return (
    <a
      href={process.env.REACT_APP_LANDING_URL}
      className="fixed bottom-[18px] text-blue md:left-[42px]"
      target="_blank"
      rel="noopener noreferrer"
    >
      Powered by Scalyz.
    </a>
  );
};

export default PoweredByScalyz;
