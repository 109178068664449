import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  modals: [
    {
      id: "modal-auth",
      open: false,
      data: null,
    },
    {
      id: "modal-cookie-policy",
      open: false,
      data: null,
    },
  ],
};

// Slice

export const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { id, data } = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      let modals = [...state.modals];
      modals[index] = {
        ...modals[index],
        open: true,
        data,
      };
      return {
        ...state,
        modals,
      };
    },
    closeModal: (state, action) => {
      const {id} = action.payload;
      const index = state.modals.findIndex((modal) => modal.id === id);
      let modals = [...state.modals];
      modals[index] = {
        ...modals[index],
        open: false,
      };
      return {
        ...state,
        modals,
      };
    },
  },
});

export default modalsSlice.reducer;

// Actions

export const { openModal, closeModal } = modalsSlice.actions;

export const triggerOpenModal = (id, data) => (dispatch) => {
  try {
    dispatch(openModal({ id, data }));
  } catch (error) {
    return console.error(error.message);
  }
};

export const triggerCloseModal = (id) => (dispatch) => {
  try {
    dispatch(closeModal({ id }));
  } catch (error) {
    return console.error(error.message);
  }
};
