import "./_index.scss";
import { Button, FormHelperText } from "@mui/material";
import LoadingImage from "components/LoadingImage";
import { useState } from "react";

const SSOForm = (props) => {
  const [apiSubmitError, setApiSubmitError] = useState("");
  const [apiSuccessMessage, setApiSuccessMessage] = useState("");
  const [submittingToApi, setSubmittingToApi] = useState(false);

  const loginWithSSO = (redirect_uri: string) => {
    redirect_uri = redirect_uri.replace(
      "%URL_CURRENT_PAGE%",
      window.location.origin
    );
    window.location.href = redirect_uri;
  };

  const githubSSO = () => {
    let github_url = process.env.REACT_APP_LABLEARNER_GITHUB_URL;
    github_url = github_url.replace(
      "%CLIENT_ID%",
      process.env.REACT_APP_LABLEARNER_GITHUB_CLIENT_ID
    );
    loginWithSSO(github_url);
  };

  const googleSSO = () => {
    setApiSubmitError("");
    setApiSuccessMessage("");
    setSubmittingToApi(true);
    let google_url = process.env.REACT_APP_LABLEARNER_GOOGLE_URL;
    google_url = google_url.replace(
      "%CLIENT_ID%",
      process.env.REACT_APP_LABLEARNER_GOOGLE_CLIENT_ID
    );
    loginWithSSO(google_url);
  };

  return (
    <>
      <img
        className="auth-form-sso-seperator"
        src={require("assets/sso-seperator.svg").default}
        alt="or"
      />
      {submittingToApi ? (
        <LoadingImage />
      ) : (
        <>
          <div className="auth-form-sso-btns">
            <Button
              className="google"
              variant="outlined"
              onClick={googleSSO}
              startIcon={
                <img
                  src={require("assets/google_icon.svg").default}
                  alt="Google"
                />
              }
            >
              Google
            </Button>
            <Button
              className="github"
              variant="outlined"
              onClick={githubSSO}
              startIcon={
                <img
                  src={require("assets/github_icon.svg").default}
                  alt="Github"
                />
              }
            >
              Github
            </Button>
          </div>
          <FormHelperText className="px-[30px] !text-red">
            {apiSubmitError}
          </FormHelperText>
          <FormHelperText className="px-[30px] !text-green">
            {apiSuccessMessage}
          </FormHelperText>
        </>
      )}
    </>
  );
};

export default SSOForm;
