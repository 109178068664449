import "./_index.scss";
import LoadingImage from "components/LoadingImage";

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <LoadingImage></LoadingImage>
    </div>
  );
};

export default LoadingScreen;
