import { useAppDispatch, useAppSelector } from "store/hooks";
import AuthModal from "components/Modals/AuthModal";
import { triggerCloseModal, triggerOpenModal } from "store/slices/modals";
import CookiePolicyModal, { initCookiePreferences } from "components/Modals/CookiePolicyModal";
import { useEffect } from "react";

const ModalsProvider = () => {
  const { modals } = useAppSelector((state) => state.modals);
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const dispatch = useAppDispatch();

  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    if (res) return res[key];
  };

  const handleOpen = (id, data) => {
    dispatch(triggerOpenModal(id, data));
  };
  const handleClose = (id) => {
    dispatch(triggerCloseModal(id));
  };

  // Login Modal
  useEffect(() => {
    if (isLoggedIn) {
      // handleClose("modal-auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  // Cookie policy
  useEffect(() => {
    if (
      localStorage.getItem(
        process.env.REACT_APP_COOKIE_BANNER_LOCALSTORAGE_KEY
      ) !== "true"
    ) {
      initCookiePreferences(true);
      handleOpen("modal-cookie-policy", null);
    } else {
      initCookiePreferences();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const closeCookieModal = () => {
    handleClose("modal-cookie-policy");
  };

  return (
    <>
      <AuthModal
        id="modal-auth"
        open={modalState("modal-auth", "open")}
        data={modalState("modal-auth", "data")}
        handleClose={handleClose}
      ></AuthModal>
      <CookiePolicyModal
        id="modal-cookie-policy"
        open={modalState("modal-cookie-policy", "open")}
        handleClose={closeCookieModal}
      ></CookiePolicyModal>
    </>
  );
};

export default ModalsProvider;
