import { Link } from "@mui/material";
import "./FooterSocials.scss";
import config from "utils/config";

const FooterSocials = (props: { socials: Array<any> }) => {
  const { socials } = props;

  return (
    <div className="footer-socials">
      {socials &&
        socials?.map((social) => (
          <Link
            key={social.type}
            className="item"
            href={social.link}
            target={social.shouldOpenInNewTab ? "_blank" : ""}
            rel={social.shouldOpenInNewTab ? "noopener noreferrer" : ""}
          >
            <img
              src={
                social.type in config.footer.mapSocialIconToImage
                  ? config.footer.mapSocialIconToImage[social.type]
                  : require("assets/Linkedin Icon.svg").default
              }
              alt={social.name}
              className={social.type}
            />
          </Link>
        ))}
    </div>
  );
};

export default FooterSocials;
