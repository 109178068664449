import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import translationFr from './locales/fr-FR/translation.json';
import translationEn from './locales/en-US/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({ 
    fallbackLng: 'en-US',
    debug: false,
    lng: 'en-US',

    resources: {
      // "fr-FR": {
      //   translation: translationFr,
      // },
      "en-US": {
        translation: translationEn,
      },
    },

    detection: {
      order: ['cookie', 'localStorage', 'querystring', 'navigator'],
      caches: ['cookie', 'localStorage'],
    },

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: false,
    },
  });

export default i18n;
