import { useEffect, useState } from "react";

const GoToTopButton = () => {
  const [hidden, setHidden] = useState(true);
  const handleGoToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
    setHidden(true);
  };
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 500) {
        setHidden(false);
      } else {
        setHidden(true);
      }
    };
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      {hidden ? null : (
        <button
          onClick={handleGoToTop}
          type="button"
          className="!fixed bottom-5 end-[85px] rounded-full  p-3 text-xs font-medium text-blue uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
          id="btn-back-to-top"
        >
          <span className="[&>svg]:w-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
              />
            </svg>
          </span>
        </button>
      )}
    </>
  );
};

export default GoToTopButton;
