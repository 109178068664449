import Link from "@mui/material/Link";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { AVATARGetUserAvatarUrl } from "utils/api";

import "./AssignmentHeader.scss";
import { AUTH_STATUS } from "components/Modals/AuthModal";
import { triggerOpenModal } from "store/slices/modals";
import { Avatar } from "@mui/material";
import { initialsFromName } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useAssignmentsContext } from "components/AssignmentsProvider";

const AssignmentHeader = () => {
  const { user, isLoggedIn }: any = useAssignmentsContext();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // Auth modal
  const handleOpenAuthModal = () =>
    dispatch(triggerOpenModal("modal-auth", AUTH_STATUS.LOGIN));
  /**
   * Opens auth modal when in Home page, url has #login and not logged in
   */
  useEffect(() => {
    if (searchParams.has("login")) {
      handleOpenAuthModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  return (
    <>
      <div className="navbar-assignment-placeholder"></div>
      <section className={`navbar-assignment`}>
        <div className="logo">
          <Link href="/">
            <img src={require("assets/logo-nuageup.png")} alt="logo" />
          </Link>
        </div>

        <div className="actions">
          {isLoggedIn && user ? (
            <div className="toolbar">
              <Link
                component="button"
                underline="none"
                className="profile_icon hover:cursor-default"
                disabled
              >
                <Avatar
                  className="profile_icon !bg-[#d9d9d9] !font-poppins !text-[12px] font-semibold !text-black"
                  src={AVATARGetUserAvatarUrl(user)}
                  alt="profile"
                  sx={{
                    border: "2px solid rgba(64, 126, 201, 1)",
                  }}
                >
                  {initialsFromName(user.firstname + " " + user.lastname)}
                </Avatar>
              </Link>
            </div>
          ) : (
            <Link onClick={handleOpenAuthModal} className="login">
              {t("sign-in")}
            </Link>
          )}
        </div>
      </section>
    </>
  );
};

export default AssignmentHeader;
