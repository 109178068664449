import { authenticate } from "store/slices/auth";
import { store } from "store/";
import axios from "axios";
import { uuidv4 } from "./utils";

const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  withCredentials: true,
});

/** Intercept any unauthorized request.
 * dispatch logout action accordingly **/
const UNAUTHORIZED = 401;
const { dispatch } = store; // direct access to redux store.
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED) {
        dispatch(authenticate(false, null));
      }
    }

    if (process.env.NODE_ENV !== "production") {
      console.log(error);
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);

// add header X-Track-ID from localstorage to all requests
API.interceptors.request.use((config) => {
  let track_id = localStorage.getItem(
    process.env.REACT_APP_TRACK_ID_LOCAL_STORAGE_KEY,
  );
  if (!track_id) {
    track_id = `${
      process.env.REACT_APP_TRACK_ID_PREFIX
        ? "web"
        : process.env.REACT_APP_TRACK_ID_PREFIX
    }-${uuidv4()}`;
    localStorage.setItem(
      process.env.REACT_APP_TRACK_ID_LOCAL_STORAGE_KEY,
      track_id,
    );
  }

  config.headers["X-Track-ID"] = track_id;
  return config;
});

export default API;

export const APIAuthenticateUserWithToken = (token) =>
  API({
    method: "get",
    url: "/oauth2",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export const APIAuthenticateUserWithProviderCode = (provider, code) =>
  API({
    method: "post",
    url: "/oauth2",
    data: {
      provider,
      code,
    },
  });
export const APILogout = () =>
  API({
    method: "get",
    url: "/logout",
  });
export const APIGetUserByID = (id) =>
  API({
    method: "get",
    url: `/user/${id}`,
  });
export const APIUpdateUserByID = (user) =>
  API({
    method: "put",
    url: `/user/${user.id}`,
    data: user,
  });
export const APIGetSettings = () =>
  API({
    method: "get",
    url: "/settings",
  });
export const APIGetSetting = (id) =>
  API({
    method: "get",
    url: `/setting/${id}`,
  });

export const APIUpdateSetting = (id, data) => {
  return API({
    method: "put",
    url: `/setting/${id}`,
    data,
  });
};

export const APIGetNotificationsToken = () =>
  API({
    method: "get",
    url: `/notifications-token`,
  });

export const APIGetMockInstance = () => {
  return {
    id: "i549dc2e0bcc14a0fa50075b3dcc12fad",
    lab_id: "lab83998d8eff4c76b9541738a36abc8f",
    owner_id: "u573a118f0606475a805ada4560780d76",
    description: "Instance description",
    goals: [
      {
        title:
          "What command is used to install Nginx on a Ubuntu Linux server?",
        description: "",
        tasks: [
          {
            title: "Single Answer (Choose one)",
            description:
              "What command is used to install Nginx on a Ubuntu Linux server?",
            type: "qcm",
            id: "qcm-1",
          },
          {
            title: "Multiple Answers (Choose two)",
            description:
              "Which two records are essential for configuring DNS to point a domain to your server?",
            type: "qcm",
            id: "qcm-2",
          },
        ],
      },
      {
        title:
          "Where is the main Nginx configuration file located on Ubuntu Linux?",
        description: "Text Answer",
        tasks: [
          {
            title: "Your Answer is here",
            description: "",
            type: "qcm",
            id: "qcm-3",
          },
        ],
      },
    ],
    resources: [
      {
        id: "qcm-1",
        title:
          "What command is used to install Nginx on a Ubuntu Linux server?",
        description: "",
        type: "qcm",
        value: {
          type: "single",
          title:
            "What command is used to install Nginx on a Ubuntu Linux server?",
          description: "Single Answer",
          proposed_answers: [
            "yum install nginx",
            "apt-get install nginx",
            "dnf install nginx",
            "pacman -S nginx",
          ],
          correct: ["apt-get install nginx"],
        },
      },
      {
        id: "qcm-2",
        title:
          "Which two records are essential for configuring DNS to point a domain to your server?",
        description: "Multiple Answers (Choose two)",
        type: "qcm",
        value: {
          type: "multiple",
          title:
            "Which two records are essential for configuring DNS to point a domain to your server?",
          description: "Multiple Answers (Choose two)",
          proposed_answers: [
            "MX Record",
            "A Record",
            "CNAME Record",
            "TXT Record",
          ],
          correct: ["A Record", "CNAME Record"],
        },
      },
      {
        id: "qcm-3",
        title:
          "Where is the main Nginx configuration file located on Ubuntu Linux?",
        description: "Text Answer",
        type: "qcm",
        value: {
          type: "text",
          title:
            "Where is the main Nginx configuration file located on Ubuntu Linux?",
          description: "Text Answer",
          proposed_answers: ["/etc/nginx/nginx.conf"],
          correct: ["/etc/nginx/nginx.conf"],
        },
      },
      {
        description:
          "This is the web server public IP. \nUser: scalyz | password: 13d8ab2cd8ecb908",
        title: "Web server IP",
        type: "IPV4",
        value: "16.171.200.24",
      },
      {
        description:
          "This is the site URL. Use this URL to access the web server.",
        title: "Website URL",
        type: "URL",
        value:
          "https://i549dc2e0bcc14a0fa50075b3dcc12fad.labs.thehotpirate.com",
      },
    ],
  };
};
export const APISubmitQCMResponse = (
  submit_url: string,
  data: any[],
  access_token: string,
) => {
  return axios.post(submit_url, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${access_token}` },
  });
};

export const APIGetInstanceResults = (
  instance_id,
  access_token,
  data: {
    instance_id: string;
    lab_name: string;
    user_id: string;
  },
) => {
  return axios.post(
    `${process.env.REACT_APP_FEEDBACK_URL}/goals/${instance_id}`,
    data,
    {
      withCredentials: true,
      headers: { Authorization: `Bearer ${access_token}` },
    },
  );
};

export const APIGetQCMResults = (
  instance_id,
  access_token,
  data: {
    instance_id: string;
    lab_name: string;
    user_id: string;
  },
) => {
  return axios.post(
    `${process.env.REACT_APP_FEEDBACK_URL}/qcm/${instance_id}`,
    data,
    {
      withCredentials: true,
      headers: { Authorization: `Bearer ${access_token}` },
    },
  );
};

export const APISubmitInstanceEvent = (
  submit_url: string,
  data: any,
  access_token: string,
) => {
  return axios.post(submit_url, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${access_token}` },
  });
};

export const APIValidateToken = () =>
  API({
    method: "get",
    url: "/user/me",
  });
export const APIGetEmbeddedCommunityToken = () =>
  API({
    method: "get",
    url: "/community-token",
  });
export const APIGetInstructorByID = (id) =>
  API({
    method: "get",
    url: `/user/${id}`,
  });
export const APIGetInstructors = () =>
  API({
    method: "get",
    url: "/users",
  });

export const APIGetLabs = (instructorID) =>
  API({
    method: "get",
    url: `/labs${instructorID ? `?owner_id=${instructorID}` : ""}`,
  });
export const APIGetAllLabs = (tags?) =>
  API({
    method: "get",
    url: `/labs${tags ? `?tags=${tags}` : ""}`,
  });
export const APIGetAllTags = () =>
  API({
    method: "get",
    url: "/labs/tags",
  });
export const APIGetLabsOfUser = (owner_id) =>
  API({
    method: "get",
    url: `/labs`,
    params: {
      owner_id,
    },
  });
export const APIGetLabBySlug = (slug) =>
  API({
    method: "get",
    url: "/labs",
    params: {
      slug: slug,
    },
  });
export const APIGetLabByID = (id) =>
  API({
    method: "get",
    url: `/lab/${id}`,
  });
export const enum LabActions {
  AddToFavorite = "add-to-favorite",
  RemoveFromFavorite = "remove-from-favorite",
  Rate = "rate",
  End = "end",
}
export const APIPostLabAction = (lab_id, action, body) =>
  API({
    method: "post",
    url: `/lab/${lab_id}/${action}`,
    data: body,
  });

export const APIGetLabsComingSoon = () =>
  API({ method: "get", url: "/labs-coming" });

export const APIGetInstanceByID = (id) =>
  API({
    method: "get",
    url: `/instance/${id}`,
  });
export const APIGetInstanceBySlug = (slug) =>
  API({
    method: "get",
    url: "/instances",
    params: {
      slug: slug,
    },
  });
export const APIPostInstanceAction = (instance_id, action, body) =>
  API({
    method: "post",
    url: `/lab/${instance_id}/${action}`,
    data: body,
  });

export const APIGetAssessmentInvitations = ({
  user_id,
  lab_slug,
  with_lab
}: {
  user_id?: string;
  lab_slug?: string;
  with_lab?: boolean;
}) =>
  API({
    method: "get",
    url: `/assessment-invitations?${user_id ? `user_id=${user_id}&` : ""}${lab_slug ? `slug_id=${lab_slug}&` : ""}${with_lab ? `with_lab=${with_lab}&` : ""}`,
  });
export const APIActionOnAssessmentInvitation = (id, action, data) =>
  API({
    method: "put",
    url: `/assessment-invitation/${id}/${action}`,
    data,
  });

export const APIGetUserReservations = (owner_id, with_lab = false) =>
  API({
    method: "get",
    url: `/reservations?user_id=${owner_id}&passed=true&with_lab=${with_lab}`,
  });
export const APIGetReservation = (user_id, lab_id) =>
  API({
    method: "get",
    url: "/reservations",
    params: {
      user_id,
      lab_id,
    },
  });
export const APIUpdateReservationByID = (id, lab_id, owner_id, date) =>
  API({
    method: "put",
    url: `/reservation/${id}`,
    data: {
      lab_id,
      owner_id,
      date,
    },
  });
export const APIDeleteReservationByID = (id) =>
  API({
    method: "delete",
    url: `/reservation/${id}`,
  });
export const APIScheduleLabInstance = (lab_id, owner_id, date) =>
  API({
    method: "post",
    url: `/reservations`,
    data: {
      lab_id,
      owner_id,
      date,
    },
  });
export const APIGetReservationSlots = (user_id, lab_id, from_date, to_date) =>
  API({
    method: "get",
    url: `/reservation-slots?user_id=${user_id}&lab_id=${lab_id}&from_date=${from_date}&to_date=${to_date}`,
  });
export const APICreateUserFeedbackAnswer = (question_group_answer) =>
  API({
    method: "post",
    url: "/user-feedback-answers",
    data: question_group_answer,
  });
export const APIGetThreads = (user_id) =>
  API({
    method: "get",
    url: "/threads",
    params: {
      user_id,
    },
  });
export const APIGetThread = (thread_id) =>
  API({
    method: "get",
    url: `/thread/${thread_id}`,
  });
export const APICreateNewThread = (
  owner_id,
  subject,
  description,
  ref_id,
  ref_resource,
) =>
  API({
    method: "post",
    url: "/threads",
    data: {
      description,
      owner_id,
      ref_id,
      ref_resource,
      subject,
    },
  });
export const APIAddMessageToThread = (thread_id, user_id, message) =>
  API({
    method: "post",
    url: `/thread/${thread_id}/message`,
    data: {
      owner_id: user_id,
      content: message,
    },
  });

export const APIUploadAvatar = (user_id, file) => {
  let formData = new FormData();
  formData.append("file", file);
  return API({
    method: "put",
    url: `/user/${user_id}/upload-avatar`,
    data: formData,
  });
};

export const AVATARGetUserAvatarUrl = (user) => {
  if (!user) return null;
  if (user.avatar) {
    return `${process.env.REACT_APP_AVATAR_URL}/${user.avatar}`;
  }
  return null;
};

export const APIGetSubscriptionPlans = () =>
  API({
    method: "get",
    url: "/subscription-plans",
  });
