import "styles/global.scss";
import "../../i18n";

import { Outlet } from "react-router-dom";
import GoToTopButton from "components/GoToTopButton";
import AssignmentHeader from "layouts/components/AssignmentHeader";
import PoweredByScalyz from "layouts/components/PoweredByScalyz";
import AssignmentsProvider from "components/AssignmentsProvider";

const AssignmentLayout = (props: { children? }) => {
  return (
    <AssignmentsProvider>
      <div className="mx-auto my-0 max-w-[1440px]">
        <GoToTopButton />
        <div className="container">
          <AssignmentHeader />
          <Outlet />
          <PoweredByScalyz />
        </div>
      </div>
    </AssignmentsProvider>
  );
};

export default AssignmentLayout;
