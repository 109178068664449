import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

export const COGNITO_ERROR_USER_NOT_VERIFIED = "UserNotConfirmedException";

const COGNITO_USER_POOL = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_LABLEARNER_COGNITO_USER_POOL_ID || "",
  ClientId: process.env.REACT_APP_LABLEARNER_COGNITO_APP_CLIENT_ID || "",
  endpoint: process.env.REACT_APP_LABLEARNER_COGNITO_ENDPOINT || "",
});

export const cognito_signin = (email, password) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    // This default auth flow is "USER_SRP_AUTH"
    if (
      process.env.REACT_APP_LABLEARNER_COGNITO_IS_USER_PASSWORD_AUTH_FLOW ===
      "true"
    ) {
      // Caution! "User_Password_Auth" flow sends the password in plain text
      cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const cognito_signup = (email, password, given_name, family_name) => {
  return new Promise((resolve, reject) => {
    COGNITO_USER_POOL.signUp(
      email,
      password,
      [
        new CognitoUserAttribute({
          Name: "family_name",
          Value: family_name,
        }),
        new CognitoUserAttribute({
          Name: "given_name",
          Value: given_name,
        }),
      ],
      null,
      (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
};

export const cognito_resend_confirmation_code = (email) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const cognito_verify = (email, code) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
};

export const cognito_forget_password = (email) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const cognito_reset_password = (email, code, password) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: COGNITO_USER_POOL,
    });
    cognitoUser.confirmPassword(code, password, {
      onSuccess: (result) => {
        resolve(result);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const removeCognitoTokensFromLocalStorage = () => {
  // "amazon-cognito-identity-js" saves tokens in the local storage. We need to remove them as we don't need them.
  for (let key in localStorage) {
    if (key.startsWith("CognitoIdentityServiceProvider")) {
      localStorage.removeItem(key);
    }
  }
};
