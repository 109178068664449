import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEmbeddedCommunityLoggedIn: false,
};

// Slice

export const embeddedCommunitySlice = createSlice({
  name: "embeddedCommunity",
  initialState,
  reducers: {
    setEmbeddedCommunityLoggedIn: (state, action) => {
      return {
        ...state,
        isEmbeddedCommunityLoggedIn: action.payload,
      };
    },
  },
});

export default embeddedCommunitySlice.reducer;

// Actions

export const { setEmbeddedCommunityLoggedIn } = embeddedCommunitySlice.actions;

export const setEmbeddedCommunity = (isEmbeddedCommunityLoggedIn) => (dispatch) => {
  try {
    dispatch(setEmbeddedCommunityLoggedIn(isEmbeddedCommunityLoggedIn));
  } catch (error) {
    return console.error(error.message);
  }
};
