import "./_index.scss";
import config from "utils/config";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingImage from "components/LoadingImage";
import TOSCheckbox from "../TOSCheckbox";
import SSOForm from "../SSOForm";
import { useTranslation } from "react-i18next";

const SignupForm = (props) => {
  const { formik, submitError, setSubmitError, handleSwitchToLogin } = props;
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const CONTENT = [
    {
      icon: require("assets/monetization_on_black_24dp.svg").default,
      title: t("absolutely-free"),
      description: t("no-hidden-charges-no-credit-card-required"),
    },
    {
      icon: require("assets/dynamic_form_black_24dp.svg").default,
      title: t("fast-and-easy"),
      description: t("sign-up-and-get-access-instantly"),
    },
    {
      icon: require("assets/ic_assignment_ind.svg").default,
      title: t("your-own-data"),
      description: t("set-your-prefrences-and-enjoy-your-free-trial"),
    },
    {
      icon: require("assets/ic_stars.svg").default,
      title: t("unlimited-features"),
      description: t("get-access-to-all-features"),
    },
  ];

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/login_illustration.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.signup_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">
          {t("get-your-free-n3-month-trial-now")}
        </div>
        <p className="auth-header-subtitle">
          {t(
            "and-start-improving-your-skills-faster-with-simplified-nand-straight-to-the-point-labs-created-for-you",
          )}
        </p>
        <div className="auth-header-content">
          {CONTENT.map((item, index) => (
            <div key={index} className="auth-header-content-details">
              <img
                src={item.icon}
                alt="icon"
                className="auth-header-content-details-icon"
              />
              <div className="auth-header-content-details-container">
                <div className="auth-header-content-details-container-title">
                  {item.title}
                </div>
                <div className="auth-header-content-details-container-description">
                  {item.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form"
        >
          <div className="signup-form">
            <div className="signup-form-title">
              {t("welcome-to-scalyz-nsign-up-to-continue")}
            </div>
            <p className="signup-form-subtitle">
              {t("already-have-an-account")}{" "}
              <Link onClick={handleSwitchToLogin}>{t("sign-in")}</Link>
            </p>
            <Grid
              container
              spacing={"24px"}
              style={{ marginTop: "6px", padding: "0 24px" }}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label={t("email-address")}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <div className="signup-form-first-name">
                  <TextField
                    fullWidth
                    name="given_name"
                    label={t("first-name")}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                    value={formik.values.given_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.given_name &&
                      Boolean(formik.errors.given_name)
                    }
                    helperText={
                      formik.touched.given_name && formik.errors.given_name
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="signup-form-last-name">
                  <TextField
                    fullWidth
                    name="family_name"
                    label={t("last-name")}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                    value={formik.values.family_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.family_name &&
                      Boolean(formik.errors.family_name)
                    }
                    helperText={
                      formik.touched.family_name && formik.errors.family_name
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label={t("password")}
                  type={showPassword ? "text" : "password"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    sx: { borderRadius: "8px" },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={t("toggle-password-visibility")}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <TOSCheckbox formik={formik} />
              <Grid item xs={12} style={{ display: "flex" }}>
                {formik.isSubmitting ? (
                  <LoadingImage></LoadingImage>
                ) : (
                  <FormControl fullWidth>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmitError("");
                        formik.handleSubmit();
                      }}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      className="signup-form-btn-signin hover:!bg-blue-dark"
                      variant="contained"
                    >
                      {t("sign-up-0")}{" "}
                    </Button>
                    <FormHelperText
                      className="signup-form-btn-signin-error"
                      style={{ color: "#EF4444" }}
                    >
                      {submitError}
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </div>
          <SSOForm />
        </div>
      </div>
    </>
  );
};

export default SignupForm;
